<template>
  <span v-if="value?.length < 4">
    <span
      v-for="address in value"
      :key="address.id"
      v-on:click="showEdit(address)"
      class="regular-12 text-black text-start badge badge-outline"
    >
      {{address.city}}
    </span>
  </span>
  <span v-else class="regular-12 text-black text-start">
    {{ $t(`addresses.savedAddress`, {length: value?.length}) }}
  </span>
  <Address
    v-if="edit"
    v-model:show="show"
    v-model="edit"
    v-on:change="modelValueDataChange"
  />
</template>

<script>
import Address from "../../inputs/Address";
export default {
  name: "Country",
  components: { Address },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
      edit: null,
      show: false,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    showEdit(data) {
      this.edit = data;
      this.show = true;
    },
    modelValueDataChange(data) {
      console.log(data);
    },
  },
};
</script>
